import type { IDataTableViewOptions } from '@x/common/data';

export const ORDER_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'order_index',
  columns: [
    {
      id: 'number',
      title: 'Number',
      sortable: true,
    },
    {
      id: 'channel',
      title: 'Channel',
    },
    {
      id: 'checkoutAt',
      title: 'Checkout Date',
    },
    {
      id: 'user',
      title: 'Customer',
    },
    {
      id: 'tags',
      title: 'Tags',
    },
    {
      id: 'shippingAddress',
      title: 'Shipping Address',
    },
    {
      id: 'geoRegion',
      title: 'Geo Region',
    },
    {
      id: 'collectionPoint',
      title: 'Collection Point',
    },
    {
      id: 'payment',
      title: 'Payment',
    },
    {
      id: 'total',
      title: 'Total',
      sortable: true,
    },
  ],
  sorts: [
    {
      id: 'id',
      title: 'ID',
    },
    {
      id: 'total',
      title: 'Order Total',
    },
    {
      id: 'number',
      title: 'Order Number',
    },
    {
      id: 'checkoutAt',
      title: 'Checkout Date',
    },
  ],
  defaultColumns: ['number', 'user', 'shippingAddress'],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
  sort: {
    column: 'number',
    order: 'desc',
  },
};
